<!--
 * @Description: 登录页面模板配置
-->
<template>
	<div class="wrap">
		<div style="padding-right:360px">
			<phone-ctn>
				<login-bar></login-bar>
			</phone-ctn>
		</div>

		<!-- 搜索配置 -->
		<config-ctn title="登录页设置">
			<!-- 			//1 设置背景图
			//设置logo
			//设置账号密码框
			//设置登录按钮样式 -->
			<div class="p10">
				<label class=" f12">此配置适用于----- 登录页</label>

				<div class="nav">
					<div class="mt10 mb15 f12 f-red">
						<config-item label='背景色'>
							<config-color-picker v-model="project.setting.login.backgroundColor"></config-color-picker>
						</config-item>
						<config-item label='背景图片'>
							<FaImageUpload :maxCount="1" v-model="project.setting.login.bgImg" @change="handleChange" />
						</config-item>
					</div>

					<div class="mt10 mb15 f12 f-red">
						<config-item label='logo顶部间距'>
							<el-input placeholder="logo距离顶部间距" v-model="project.setting.login.logoMarginTop"
								size="small" @change="handleChange"></el-input>
						</config-item>
						<config-item label='logo'>
							<FaImageUpload :maxCount="1" v-model="project.setting.login.logo" @change="handleChange" />
						</config-item>
						<config-item label='logo宽度'>
							<el-input placeholder="logo宽度(px,vw.%)" v-model="project.setting.login.logoWidth"
								size="small" @change="handleChange"></el-input>
						</config-item>
						<config-item label='logo高度'>
							<el-input placeholder="logo高度(px,vw.%)" v-model="project.setting.login.logoHeight"
								size="small" @change="handleChange"></el-input>
						</config-item>
						<config-item label='logo圆角'>
							<el-input placeholder="logo高度(px,vw.%)" v-model="project.setting.login.logoRadius"
								size="small" @change="handleChange"></el-input>
						</config-item>

					</div>

					<config-item label='显示输入区域白底'>
						<el-switch class="mt8" v-model="project.setting.login.showInputBg">
						</el-switch>
					</config-item>

					<div class="mt10 mb15 f12 f-red">
						<config-item label='标题1'>
							<el-input placeholder="请输入标题" v-model="project.setting.login.title" size="small"
								@change="handleChange"></el-input>
						</config-item>

						<config-item label='标题1文字颜色'>
							<config-color-picker v-model="project.setting.login.titleFontColor"></config-color-picker>
						</config-item>

						<config-item label='标题1文字上边间距'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.titleMarginTop"></el-input>
						</config-item>

						<config-item label='标题1文字字体'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.titleFontSize"></el-input>
						</config-item>

						<config-item label='标题1文字粗细'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.titleFontWeight"></el-input>
						</config-item>
					</div>

					<div class="mt10 mb15 f12 f-red">
						<config-item label='标题2'>
							<el-input placeholder="请输入标题" v-model="project.setting.login.title2" size="small"
								@change="handleChange"></el-input>
						</config-item>

						<config-item label='标题2文字颜色'>
							<config-color-picker v-model="project.setting.login.titleFontColor2"></config-color-picker>
						</config-item>
						<config-item label='标题2文字上边间距'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.titleMarginTop2"></el-input>
						</config-item>
						<config-item label='标题2文字字体'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.titleFontSize2"></el-input>
						</config-item>
						
						<config-item label='标题2文字粗细'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.titleFontWeight2"></el-input>
						</config-item>
					</div>


					<div class="mt10 mb15 f12 f-red">
						<config-item label='账号密码区域上边间距'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.inputMarginTop"></el-input>
						</config-item>
						<config-item label='账号密码区域下边间距'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.inputMarginBottom"></el-input>
						</config-item>
						<!-- 账号密码输入框样式 -->
						<config-item label='输入框圆角'>
							<el-input placeholder="请输入" size="small" @change="handleChange"
								v-model="project.setting.login.inputRadius"></el-input>
						</config-item>
						<config-item label='输入框边框'>
							<el-input placeholder="请输入" size="small" @change="handleChange"
								v-model="project.setting.login.inputBorder"></el-input>
						</config-item>
						<config-item label='输入框底色'>
							<config-color-picker v-model="project.setting.login.inputBackground"></config-color-picker>
						</config-item>
						<config-item label='文字颜色'>
							<config-color-picker v-model="project.setting.login.inputColor"></config-color-picker>
						</config-item>
						<!-- 账号密码输入框样式 -->
						<config-item label='文字对齐方式'>
							<el-input placeholder="left,center,right" size="small" @change="handleChange"
								v-model="project.setting.login.inputTextAlign"></el-input>
						</config-item>
						<config-item label='显示分割线'>
							<el-switch class="mt8" v-model="project.setting.login.inputShowLine">
							</el-switch>
						</config-item>

					</div>

					<div class="mt10 mb15 f12 f-red">
						<config-item label='登录按钮文字'>
							<el-input placeholder="请输入名字" v-model="project.setting.login.btnTitle" size="small"
								@change="handleChange"></el-input>
						</config-item>

						<config-item label='按钮背景颜色'>
							<config-color-picker v-model="project.setting.login.btnBgColor"></config-color-picker>
						</config-item>

						<config-item label='按钮高度(px,vw.%)'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnHeight"></el-input>
						</config-item>

						<config-item label='按钮宽度(px,vw.%)'>
							<el-input placeholder="请输入屏幕宽度的百分比,一般默认90" size="small" @change="handleChange"
								v-model="project.setting.login.btnWidth"></el-input>
						</config-item>

						<config-item label='按钮上边圆角'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnUpperRadius"></el-input>
						</config-item>

						<config-item label='按钮下边圆角'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnLowerRadius"></el-input>
						</config-item>

						<config-item label='按钮距上边间距'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnMarginTop"></el-input>
						</config-item>

						<config-item label='按钮距下边间距'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnMarginBottom"></el-input>
						</config-item>
					</div>

					<div class="mt10 mb15 f12 f-red">
						<config-item label='协议选中图片'>
							<FaImageUpload :maxCount="1" v-model="project.setting.login.agreementSelected"
								@change="handleChange" />
						</config-item>
						<config-item label='协议未选图片'>
							<FaImageUpload :maxCount="1" v-model="project.setting.login.agreementUnselect"
								@change="handleChange" />
						</config-item>
					</div>

					<div class="mt10 mb15 f12 f-red">
						<!-- 					<config-item label='显示顶部导航'>
							<el-switch class="mt8" v-model="project.setting.login.showTopNav">
							</el-switch>
						</config-item> -->
						<config-item label='验证码登录'>
							<el-switch class="mt8" v-model="project.setting.login.showTelLogin">
							</el-switch>
						</config-item>
						<config-item label='显示注册'>
							<el-switch class="mt8" v-model="project.setting.login.showRegist">
							</el-switch>
						</config-item>
						<config-item label='显示忘记密码'>
							<el-switch class="mt8" v-model="project.setting.login.showForgetPwd">
							</el-switch>
						</config-item>

					</div>

				</div>


			</div>
		</config-ctn>
	</div>
</template>

<script>
	import LoginBar from "@/components/LoginBar";
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "loginTpl",
		data() {
			return {

			}
		},
		components: {
			LoginBar
		},

		computed: {
			...mapGetters(["project"]),
		},
		mounted() {
			console.log('---->', this.project);
		},
		onload() {


		},
		methods: {
			//改变图片后立刻同步到
			handleChange() {
				console.log('The message is changed.');
			}
		},
	};
</script>

<style lang="scss" scoped>
	.nav-item {
		padding: 10px;
		margin-bottom: 10px;
		border-radius: 2px;
		background-color: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}
</style>